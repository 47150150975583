@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary: #111111;
  --secondary: #252525;
  --green: #9abb53;
  --red: #bc3326;
  --blue: #0070d8;
  --orange: #ef882e;
  --scrollbar-color: #00000000;
}

html {
  margin: 0;
}

body {
  color: white;
  margin: 0;
  padding: 0;
  background: #1a1a1a;
  font-family: Montserrat;
  overflow-y: hidden;
  overflow-x: hidden;
}

.dragging {
  opacity: 0.5;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 1px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

*:hover,
*:focus,
*:focus-within {
  --scrollbar-color: #555 !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #00000000;
  border: 5px solid transparent;
  border-radius: 24px;
  box-shadow: 4px 0px 0px 4px var(--scrollbar-color) inset;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color);
  border: 0px solid transparent;
  box-shadow: none;
}
