.relays__container {
  margin-left: 18.25rem;
  margin-right: 1rem;
  padding-top: 1.5rem;
}

.relays__top-header {
  display: flex;
  justify-content: space-between;
}

.relays__top-header-left {
  text-align: left;
  color: white;
  font-weight: 500;
  font-size: 0.8rem;
}

.relays__top-header-left span {
  font-weight: 300;
  color: #a0aec0;
}

.relays__top-header-left h4 {
  margin: 0rem;
  margin-top: 0.5rem;
}

.relays__graph-button {
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: Roboto;
  background-color: var(--secondary);
  border: none;
  border-radius: 0.4rem;
  font-size: 1rem;
  padding: 0.3rem 1rem;
  color: rgb(112, 112, 112);
  font-weight: 500;
}

.relays__links-container {
  background-color: var(--primary);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem 1rem;
  border-radius: 1rem;
  border: 2px solid #2e2e2e;
  height: calc(100vh - 8.55rem);
  max-height: calc(100vh - 8.55rem);
}

.relays__links-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.relays__links-header__left h5 {
  margin: 0rem;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.relays__detected-text {
  font-size: 0.8rem;
  color: rgb(104, 104, 104);
}

.relays__detected-text span {
  font-weight: 700;
}

.relays__filters-button {
  font-family: Roboto;
  background-color: var(--secondary);
  border: none;
  height: 2.5rem;
  border-radius: 0.4rem;
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  color: rgb(112, 112, 112);
  font-weight: 400;
}

.relays__links-header__right {
  display: flex;
}

.relays__search-bar {
  height: 2.34rem;
  margin-left: 1rem;
  margin-right: 0.4rem;
}

.relays__search-bar input {
  /* width: 10rem; */
  height: 100%;
  border: 2px solid #2e2e2e;
  width: 25rem;
  background-color: var(--primary);
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0 1.5rem 0 2.5rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: 1rem 48%;
  color: white;
}

.relays__list-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 7.5rem;
  gap: 1rem;
  overflow: auto;
}

.relays__list-element {
  display: flex;
  flex-direction: column;
  background-color: #1b1b1b;
  border-radius: 0.8rem;
  padding: 1rem;
  box-sizing: border-box;
}

.relays__list-element button {
  background-color: var(--red);
  width: fit-content;
  padding: 0.5rem 0.8rem;
  border-radius: 0.4rem;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  border: none;
  margin-top: auto;
}

.relays__list-element-uptime {
  font-size: 0.8rem;
  margin-top: 0.2rem;
  color: rgb(184, 184, 184);
}

.relays__list-element-active {
  border: 1px solid var(--green);
}

.relays__list-element-inactive {
  border: 1px solid var(--red);
}

.relays__flag {
  background-color: var(--red);
  width: fit-content;
  padding: 0.5rem 0.8rem;
  border-radius: 0.4rem;
  font-weight: 600;
  font-size: 0.7rem;
  text-align: center;
}

.relays__wallet {
  margin-top: 1rem;
  font-size: 0.65rem;
  font-weight: 700;
}

.relays__tag-holder {
  margin-top: auto;
  display: block;
  gap: 1rem;
  margin-bottom: 0.25rem;
}

.relays__tag {
  border-radius: 0.4rem;
  background-color: var(--secondary);
  align-items: center;
  margin-right: 0.5rem;
  padding: 0.5rem 0.7rem;
}

.relays__normal {
  background-color: var(--green);
}

.relays__suspicious {
  background-color: var(--orange);
}

.relays__criminal {
  background-color: var(--red);
}

.relays__official {
  background-color: var(--blue);
}
