.node__container {
  margin-left: 18.25rem;
  margin-right: 20.8rem;
  padding-top: 1.5rem;
}

.node__top-header {
  display: flex;
  justify-content: space-between;
}

.node__top-header-left {
  text-align: left;
  color: white;
  font-weight: 500;
  font-size: 0.8rem;
}

.node__top-header-left span {
  font-weight: 300;
  color: #a0aec0;
}

.node__top-header-left h4 {
  margin: 0rem;
  margin-top: 0.5rem;
}

.node__graph-button {
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: Roboto;
  background-color: var(--secondary);
  border: none;
  border-radius: 0.4rem;
  font-size: 1rem;
  padding: 0.3rem 1rem;
  color: rgb(112, 112, 112);
  font-weight: 500;
}

.node__links-container {
  background-color: var(--primary);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem 1rem;
  border-radius: 1rem;
  border: 2px solid #2e2e2e;
  height: calc(100vh - 8.55rem);
  max-height: calc(100vh - 8.55rem);
}

.node__links-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.node__links-header__left h5 {
  margin: 0rem;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.node__detected-text {
  font-size: 0.8rem;
  color: rgb(104, 104, 104);
}

.node__detected-text span {
  font-weight: 700;
}

.node__filters-button {
  font-family: Roboto;
  background-color: var(--secondary);
  border: none;
  height: 2.5rem;
  border-radius: 0.4rem;
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  color: rgb(112, 112, 112);
  font-weight: 400;
}

.node__links-header__right {
  display: flex;
}

.node__search-bar {
  height: 2.34rem;
  margin-left: 1rem;
  margin-right: 0.4rem;
}

.node__search-bar input {
  /* width: 10rem; */
  height: 100%;
  border: 2px solid #2e2e2e;
  width: 25rem;
  background-color: var(--primary);
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0 1.5rem 0 2.5rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: 1rem 48%;
  color: white;
}

.node__links-table-container {
  display: block;
  overflow-y: overlay;
  overflow-x: auto;
}

.node__links-table {
  width: 100%;
  text-align: center;
  border: 0;
  border-collapse: separate;
  border-spacing: 0 5px;
}

.node__links-table-head {
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: var(--primary);
}

.node__links-table-head tr th {
  font-size: 0.7rem;
  font-weight: 400;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(85, 85, 85);
}

.node__links-table-body tr td {
  border-bottom: 1px solid rgb(85, 85, 85);
  padding: 1rem 0rem 1.4rem 0rem;
}

.node__flag {
  background-color: var(--secondary);
  width: 7.25rem;
  padding: 0.5rem 0.9rem;
  border-radius: 0.4rem;
  font-weight: 600;
}

.node__normal {
  background-color: var(--green);
}

.node__suspicious {
  background-color: var(--orange);
}

.node__criminal {
  background-color: var(--red);
}

.node__official {
  background-color: var(--blue);
}
